import { HomepageProvider } from '@/components/homepageContext/HomepageProvider';
import { HomepageSections } from '@/components/homepageSections/HomepageSections';
import { HomepageSEO } from '@/components/homepageSeo/HomepageSEO';

export const HomepageRoute = ({ proCustomer }: { proCustomer?: boolean }) => {
  return (
    <HomepageProvider proCustomer={proCustomer}>
      <HomepageSEO />
      <HomepageSections />
    </HomepageProvider>
  );
};
