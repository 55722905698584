import { useQueries } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import { createContext } from 'use-context-selector';
import type { GetHomepageQuery } from '@/lib/contentful/__generated__/HomepageQuery';
import { useGetHomepageQuery } from '@/lib/contentful/__generated__/HomepageQuery';
import type { GetHomepageAdditionalSections1Query } from '@/lib/contentful/__generated__/HomepageQueryAdditionalSections1';
import { useGetHomepageAdditionalSections1Query } from '@/lib/contentful/__generated__/HomepageQueryAdditionalSections1';
import { contentfulQueryDataSource } from '@/lib/contentful/dataSources';
import { storeLocale } from '@/root/constants';
import { formatContentfulSections } from '@/utils/format/contentful/formatContentfulSections';
import type {
  FormattedContentfulSection,
  InputContentfulSection,
  InputContentfulSections,
} from '@/utils/types/contentfulSections';
import type { StoreSeo } from '@/utils/types/storeTypes';

export type HomepageContext = {
  sectionCount?: number;
  sections?: FormattedContentfulSection[];
  isLoading: boolean;
  seo?: StoreSeo;
};

export const HomepageContext = createContext<HomepageContext | undefined>(
  undefined
);

export function HomepageProvider({
  proCustomer,
  children,
}: {
  proCustomer?: boolean;
  children: ReactNode;
}) {
  const { locale, query } = useRouter();

  const preview = typeof query.preview === 'string' ? !!query.preview : false;

  const queryVariables = {
    locale: storeLocale(locale),
    preview: preview,
  };

  const homepageQueries = useQueries({
    queries: [
      {
        queryKey: useGetHomepageQuery.getKey(queryVariables),
        queryFn: useGetHomepageQuery.fetcher(
          contentfulQueryDataSource({ byPassCache: preview }),
          queryVariables
        ),
      },
      {
        queryKey: useGetHomepageAdditionalSections1Query.getKey(queryVariables),
        queryFn: useGetHomepageAdditionalSections1Query.fetcher(
          contentfulQueryDataSource({ byPassCache: preview }),
          queryVariables
        ),
      },
    ],
  });

  const isAnyLoading = homepageQueries.some((query) => query.isLoading);

  const queriesData = homepageQueries.map((query) => query.data);
  const data = formatHomepageQueriesData(queriesData, proCustomer);

  const contextValue = useMemo(() => {
    if (data) {
      return {
        ...data,
        isLoading: isAnyLoading,
      };
    }

    return {
      isLoading: isAnyLoading,
    };
  }, [data, isAnyLoading]);

  return (
    <HomepageContext.Provider value={contextValue}>
      {children}
    </HomepageContext.Provider>
  );
}

/**
 * Format the data from the various homepage queries to be used in the context.
 * @param queriesData - array of query data.
 * @returns formatted data for provider
 */
/* eslint-disable sonarjs/cognitive-complexity */
const formatHomepageQueriesData = (
  queriesData: Array<
    GetHomepageQuery | GetHomepageAdditionalSections1Query | undefined
  >,
  proCustomer?: boolean
) => {
  // If none of the queries have homepage data, return false.
  if (queriesData.every((query) => !query?.homepage)) {
    return false;
  }

  // Filter out the invalid or errored requests that have no data.
  const validQueriesData = queriesData.filter((query) => query?.homepage);

  if (validQueriesData.length === 0) {
    return false;
  }

  const seo = {
    title: '',
    description: '',
  };

  validQueriesData.forEach((data) => {
    if (!data || !data.homepage) {
      return;
    }

    if ('seoTitle' in data.homepage || 'seoDescription' in data.homepage) {
      seo.title = data.homepage.seoTitle ?? '';
      seo.description = data.homepage.seoDescription ?? '';
    }
  });

  // Grab the section count from the first valid request.
  const sectionCount =
    validQueriesData[0]?.homepage?.sectionsCollection?.total ?? 0;

  /**
   * Loop through to get the sections data.
   */
  let sections: FormattedContentfulSection[] = [];

  // Map to return array of sections for each query.
  const validSectionsData = validQueriesData.map(
    (query) => query?.homepage?.sectionsCollection?.items
  );

  /**
   * Loop to merge the section data.
   * - Gets the first query data sections.
   * - If that data has any section with only 1 property (the `__typename`) key
   *   then loop through the other requests to find the full data.
   * - Format the array of valid sections with the contentful section formatter
   * - Update the sections array to use the formatted data.
   */
  if (validSectionsData && validSectionsData[0]?.length) {
    const sectionsToFormat: InputContentfulSections = [];

    validSectionsData[0].forEach((section, index) => {
      if (!section) {
        return;
      }

      /**
       * If section has more than one key, it has the full section data, so
       * push it to the `sectionsToFormat` array.
       * Otherwise find the array that does have the data.
       */
      const keyCount = Object.keys(section).length;
      if (keyCount > 1) {
        sectionsToFormat.push(section as InputContentfulSection);
        return;
      }

      /**
       * Loop through the other request data to find if any contains the full
       * request data.
       */
      let matchedSection;
      validSectionsData.forEach((queryData) => {
        const sectionData = queryData?.[index];
        if (sectionData && Object.keys(sectionData).length > 1) {
          matchedSection = sectionData;
        }
      });

      if (matchedSection) {
        sectionsToFormat.push(matchedSection);
      }
    });

    sections = formatContentfulSections(sectionsToFormat, proCustomer);
  }

  return {
    sectionCount,
    sections,
    seo,
  };
};
