import type { NextPage } from 'next';
import Head from 'next/head';
import { HomepageRoute } from '@/components/route/homepage/HomepageRoute';
import { homepageSSRProps } from '@/components/route/homepage/homepageSSRProps';

const Home: NextPage = () => {
  return (
    <>
      <Head>
        <title>OPI Nail Polish, Nail Care & Nail Art | OPI®</title>
        <meta
          name="description"
          content="Explore the Official OPI® Site and discover the latest in OPI nail polishes and gels, nail care systems, and nail art trends. Get the latest and get inspired."
        />
        {/* Google Domain Verification */}
        <meta
          name="google-site-verification"
          content="cIlY71Tj78tSzVEMHN-r7Xi9hocibnLG0qsXi_0U5sM"
        />
        {/* Facebook/Meta Domain Verification */}
        <meta
          name="facebook-domain-verification"
          content="s2t68v3t84tzjunfa8t5yl97d6mluc"
        />
      </Head>

      <HomepageRoute />
    </>
  );
};

export const getServerSideProps = homepageSSRProps;

export default Home;
